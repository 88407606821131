import SleepMonitorPagination from "../components/sleep-monitor-query-pagination.vue";
import {
    getMattressMonitorInfos
} from '../../../../api/api'

import session from "../../../../store/store";

export default {
    components: {
        SleepMonitorPagination,
    },
    data() {
        return {
            //定义变量
            type: 0,//0非全屏  1：全屏
            role: 0,
            count: 0,
            fullScreen: false,
            queryPage: false,
            userList: [],
            timer: null,
            currentPage: 1,
            size: 15,
            maxPage:14,
        };
    },

    mounted() {
        this.init()
        if(this.$route.query.type != null) {
          this.type = this.$route.query.type;
          document.title = session.getSysName() + '-' + '睡眠监控中心'
        }
    },

    methods: {
        init() {
            this.role = session.getLoginInfo().accountType;
            this.getMattressMonitorInfos(); //userList
        },
        //"总条数"：rowCount，"每页总条数"：pageSize
        pageTotal(rowCount, pageSize) {
            if (rowCount == null || rowCount == "") {
                return 0;
            } else {
                if (pageSize != 0 && rowCount % pageSize == 0) {
                    return parseInt(rowCount / pageSize)
                }
                if (pageSize != 0 && rowCount % pageSize != 0) {
                    return parseInt(rowCount / pageSize) + 1;
                }
            }
        },
        /**
         * 点击换页
         * @param {int} cur 
         */
        sleepPaging(cur) {
            if (this.currentPage != cur) {
                this.currentPage = cur
                this.getMattressMonitorInfos();
            }
        },
        jump(value) {
            this.sleepPaging(value);
        },
        /**
         * 获取睡眠监控列表信息
         */
         getMattressMonitorInfos() {
            var param = {
                unitUuid:session.getLoginInfo().unitUuid,//83d4d390ff8080810183d53065340003
                role:this.role,
                page:this.currentPage - 1,
                size:this.size,
                terminal:1
            }

            getMattressMonitorInfos(param).then(res => {
                
                if (res.data.code == 200) {
                    this.userList = res.data.dataInfo
                    this.count = this.pageTotal(res.data.count, this.size)
                } else {
                    this.count = 0;
                    this.userList.splice(0, this.userList.length);
                }
            })
        },
        jumpPage() {
            this.queryPage = true;
            setTimeout(() => {
                this.$refs.childView.init();
            }, 200);
        },

        magnify() {
            const routeData = this.$router.resolve({
                path: "/sleep-monitor-full-screen",
                query: {
                    type: 1     //全屏
                 } 
              })
            window.open(routeData.href)
          }

    },

};