<template>
  <div style="height: 100%;width:100%;position: absolute;">
    <div class="title">
      <span class="text-gradient">睡 眠 监 控 中 心</span>
    </div>
    <div style="float: right; width: 20%;position: absolute; right: 5px;top: 1px;">
        <div style="margin-top: 13px;">
            <div class="secondspan" v-if="this.type == 0">
                <img src="../../../assets/img/fullscreen.png" @click="magnify()"/>
            </div>
        </div>
    </div>
    <!-- 颜色的状态 1：在床 数据都正常  2：离线  3：离床  4：体动  5：在床，有数据异常 -->
    <!-- <div class="page" style="position: relative;margin-top: 25px;"  >
          <div style="position: absolute; right: 10px;top: 1px;">
            <div v-for="pageCount of this.count" :key="pageCount">
              <div class="pageBox" v-if="pageCount <= this.maxPage" :class="currentPage == pageCount ? 'checkPageBox' : ''"
                @click="sleepPaging(pageCount)">
                <div style="color: white" v-text="pageCount"></div>
              </div>
            </div>
            <div v-if="this.count > this.maxPage" class="pageBox" @click="jumpPage()">
              <img src="../../../assets/img/sous.png" />
            </div>
          </div>
          <SleepMonitorPagination  v-show="this.queryPage"  ref="childView" @cancel="this.queryPage = false" @jump="jump" :currentPage="currentPage" :total="count" ></SleepMonitorPagination>

        </div> -->

    <div class="page" style="position: relative;" >
      <div class="device-box">
        <div
          class="frame"
          
          :class="[
          //   item.colorState == '1' ? 'inbed' : '',
          //   item.colorState == '5' ? 'risk' : '',
          //   item.colorState == '2' ? 'offline' : '',
          //   item.colorState == '3' ? 'outbed' : '',
          //   item.colorState == '4' ? 'bodymove' : '',
          ]"
          style="height: 244px;"
          v-for="(item, index) in this.userList"
          :key="index"
        >
          <div style="display: flex; align-items: center;margin: 10px 0px 20px 9px;" v-if="item.userName != undefined">
            <div class="frameName">
              <span v-text="item.userName"></span>
              <span> | </span>
            </div>
            <div class="age" style="margin: 0px 4px">
              <span v-text="item.age + '岁'"></span>
            </div>
            <div class="gender">
              <span> | </span>
              <span v-if="item.sex == '1'">男</span>
              <span v-if="item.sex == '0'">女</span>
            </div>
          </div>

          <div style="height:28px;margin: 10px 0px 20px 9px;" v-if="item.userName == undefined">
              <div style="height:23px"></div>
          </div>


          <div style="text-align: center">
            <i
              class="iconfont-sleep iconzaichuang stateIcon"
              :class="[
                item.colorState == '1' ? 'inbedspan' : '',
                item.colorState == '5' ? 'riskspan' : '',
              ]"
              v-if="item.deviceState == '1' && item.userState == '1'"
            ></i>
            <i
              class="iconfont-sleep iconlichuang stateIcon outbedspan"
              v-if="item.deviceState == '1' && item.userState == '2'"
            ></i>
            <i
              class="iconfont-sleep iconlixian stateIcon offlinespan"
              v-if="item.deviceState == 2"
            ></i>
            <i
              class="iconfont-sleep icontidong stateIcon bodymovespan"
              v-if="item.deviceState == '1' && item.userState == '3'"
            ></i>
          </div>
          <div class="state">
            <span
              v-if="item.deviceState == '1' && item.userState == '1'"
              :class="[
                item.colorState == '1' ? 'inbedspan' : '',
                item.colorState == '5' ? 'riskspan' : '',
              ]"
              style="font-size:20px;"
              >在床</span>
            <span
              v-if="item.deviceState == '1' && item.userState == '2'"
              class="outbedspan"
              style="font-size:20px;"
              >离床</span>
            <span v-if="item.deviceState == 2" class="offlinespan">离线</span>
            <span
              v-if="item.deviceState == '1' && item.userState == '3'"
              class="bodymovespan"
              style="font-size:20px;"
              >体动</span>

            <span
              v-if="item.deviceState == '1' && item.userState == '4'"
              class="offlinespan"
              style="font-size:20px;"
              >检测中</span>

            <span
              v-if="item.deviceState == '0' && item.userState == '0'"
              class="offlinespan"
              style="font-size:20px;"
              >检测中</span>
          </div>
          <div style="display: flex; justify-content: center; align-content: center;">
            <div class="lungName">
              <i
                class="iconfont-sleep iconhuxishuai heartRateIcon"
                :class="[
                  item.colorState == '1' ? 'inbedspan' : '',
                  item.colorState == '5' ? 'riskspan' : '',
                  item.colorState == '2' ? 'offlinespan' : '',
                  item.colorState == '3' ? 'outbedspan' : '',
                  item.colorState == '4' ? 'bodymovespan' : '',
                ]"
              ></i>
              <span
                :class="[
                  item.colorState == '1' ? 'inbedspan' : '',
                  item.colorState == '5' ? 'riskspan' : '',
                  item.colorState == '2' ? 'offlinespan' : '',
                  item.colorState == '3' ? 'outbedspan' : '',
                  item.colorState == '4' ? 'bodymovespan' : '',
                ]"
                v-if="
                  item.colorState != '2' &&
                  item.colorState != '3' &&
                  item.colorState != '4'
                "
                >{{ item.respiratoryRate }}</span>
            </div>
            <div
              v-if="
                item.colorState == '2' ||
                item.colorState == '3' ||
                item.colorState == '4'
              "
              style="margin: 20px 19px 0px -5px; font-weight: 900"
              :class="[
                item.colorState == '1' ? 'inbedspan' : '',
                item.colorState == '5' ? 'riskspan' : '',
                item.colorState == '2' ? 'offlinespan' : '',
                item.colorState == '3' ? 'outbedspan' : '',
                item.colorState == '4' ? 'bodymovespan' : '',
              ]"
            >
              ---
            </div>
            <div class="heartRate">
              <i
                class="iconfont-sleep iconxinshuai heartRateIcon"
                :class="[
                  item.colorState == '1' ? 'inbedspan' : '',
                  item.colorState == '5' ? 'riskspan' : '',
                  item.colorState == '2' ? 'offlinespan' : '',
                  item.colorState == '3' ? 'outbedspan' : '',
                  item.colorState == '4' ? 'bodymovespan' : '',
                ]"
              ></i
              ><span
                class=""
                v-if="
                  item.colorState != '2' &&
                  item.colorState != '3' &&
                  item.colorState != '4'
                "
                :class="[
                  item.colorState == '1' ? 'inbedspan' : '',
                  item.colorState == '5' ? 'riskspan' : '',
                  item.colorState == '2' ? 'offlinespan' : '',
                  item.colorState == '3' ? 'outbedspan' : '',
                  item.colorState == '4' ? 'bodymovespan' : '',
                ]"
                v-text="item.heartRate"
              ></span>
            </div>
            <div
              v-if="
                item.colorState == '2' ||
                item.colorState == '3' ||
                item.colorState == '4'
              "
              style="margin: 20px 6px 0px 0px; font-weight: 900"
              :class="[
                item.colorState == '1' ? 'inbedspan' : '',
                item.colorState == '5' ? 'riskspan' : '',
                item.colorState == '2' ? 'offlinespan' : '',
                item.colorState == '3' ? 'outbedspan' : '',
                item.colorState == '4' ? 'bodymovespan' : '',
              ]"
            >
              ---
            </div>
          </div>
          <div class="warning"  style="display: flex; align-items: center;">
              <span style="text-align: center;margin-left: 10px;">设备ID:</span>
              <span style="text-align: center;margin-left: 5px;margin-top: 4px;">{{item.deviceId}}</span >
            </div>
        </div>
      </div>

      <div style="position: absolute; right: 5px;top: 1px;">
        <div v-for="pageCount of this.count" :key="pageCount">
          <div class="pageBox" v-if="pageCount <= this.maxPage" :class="currentPage == pageCount ? 'checkPageBox' : ''"
            @click="sleepPaging(pageCount)">
            <div style="color: white" v-text="pageCount"></div>
          </div>
        </div>
        <div v-if="this.count > this.maxPage" class="pageBox" @click="jumpPage()">
          <img src="../../../assets/img/sous.png" />
        </div>
      </div>
      <SleepMonitorPagination  v-show="this.queryPage"  ref="childView" @cancel="this.queryPage = false" @jump="jump" :currentPage="currentPage" :total="count" ></SleepMonitorPagination>


    </div>
  </div>
</template>

<script src="./js/sleep-monitor.js"></script>
<style src="./css/sleep-monitor.css" scoped></style>


