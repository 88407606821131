<template>
    <div>
      <el-dialog v-model="dialogVisible" title="页码跳转" width="40%" :close-on-click-modal="false" 
              :close-on-press-escape="false" :show-close="false" >
        <div style="display: flex;">
          <div v-for="pageCount of getMaxPage()" :key="pageCount">
            <div class="page-box" :class="currentPage == pageCount ? 'check-page-box' : ''" @click="jump(pageCount)">
              <span style="color: white">{{ pageCount }}</span>
            </div>
          </div>
          <div class="page-input" style="margin: 0px 10px">
            <el-input  v-model="value" placeholder="页码" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
          </div>
          <div>
            <div class="page-box" :class="currentPage == total ? 'check-page-box' : ''" @click="jump(total)">
              <span style="color: white">{{ total }}</span>
            </div>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel" size="small">取消</el-button>
            <el-button type="primary" @click="sure" size="small" >确定</el-button>
          </span>
        </template>

      </el-dialog>
    </div>
</template>

<script>
import until from "../../../../until/until";
export default {
  props: {
    total: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
  },
  data() {
    return {
      dialogVisible: false,
      value: "",
      maxPage:7
    };
  },
  methods: {
    //页面初始化时加载的事件
    init() {
      this.dialogVisible = true;
    },

    getMaxPage() {
      if(this.total <= this.maxPage) {
        return this.total;
      }else {
        return this.maxPage
      }
    },

    //关闭页面
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
      this.input = "";
    },
    //提交分页
    sure() {
      if (until.isNULL(this.value)) {
        this.$message.error('请输入页码');
        return;
      }
      this.jump(Number(this.value));
    },
    jump(pageCount) {
      this.$emit("jump",pageCount);
      this.cancel();
    },
  },
};
</script>


<style src="../css/query-paging.css" scoped></style>

<style>
.page-input /deep/ .el-input__inner {
  background-color: rgba(30, 58, 124, 1);
  border: 1px solid #3a8ee6;
}
</style>